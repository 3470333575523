import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { NgApexchartsModule } from 'ng-apexcharts';

// ANCHOR NGPRIME
import { TableModule } from 'primeng/table';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { SlideMenuService } from './services/slide-menu.service';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { SlideMenuComponent } from './components/slide-menu/slide-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { HeaderDashboardComponent } from './components/header-dashboard/header-dashboard.component';
import { FooterDashboardComponent } from './components/footer-dashboard/footer-dashboard.component';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './services/loader.service';

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    SideBarComponent,
    SlideMenuComponent,
    MenuItemComponent,
    HeaderDashboardComponent,
    FooterDashboardComponent,
    SessionLayoutComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    NgbModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TableModule,
    NgApexchartsModule,
  ],
  providers: [SlideMenuService, AuthService, LoaderService],
  bootstrap: [AppComponent],
})
export class AppModule {}
