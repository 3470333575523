import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-dashboard',
  templateUrl: './footer-dashboard.component.html',
  styleUrl: './footer-dashboard.component.scss'
})
export class FooterDashboardComponent {

}
