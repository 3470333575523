import { Component } from '@angular/core';

@Component({
  selector: 'app-session-layout',
  templateUrl: './session-layout.component.html',
  styleUrl: './session-layout.component.scss',
})
export class SessionLayoutComponent {
  public outerHeight: number = document.documentElement.clientHeight;
}
