<div style="top: 0px; z-index: 1024" class="position-absolute w-100 h-100">
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div
        class="col-6 col-md-3 col-lg-3 col-xl-2 bg-primary p-0 d-flex align-items-between h-100"
      >
        <div class="container-fluid">
          <div class="row h-100">
            <div class="col-12 p-0">
              <app-side-bar></app-side-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
