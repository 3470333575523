<div
  style="top: 0px; z-index: 10024"
  class="position-absolute bg-white w-100 h-100"
>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <div class="">
      <div class="loader"></div>
      <div class="text-center">
        <h3>
          <b>{{ loaderService.message }}</b>
        </h3>
      </div>
    </div>
  </div>
</div>
