import { Component } from '@angular/core';
import { MENU } from '../../data/menu';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss',
})
export class SideBarComponent {
  public menuData = MENU;

  public isCollapsed = true;

  public firstName: string = 'First Name';
}
