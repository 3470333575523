import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { user } from '@angular/fire/auth';

export const IsNotLoggedInGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const loaderService = inject(LoaderService);

  return new Promise((resolve, reject) => {
    user(authService.auth).subscribe((_user) => {

      loaderService.loading = false;
      loaderService.message = '';

      if (_user){
        router.navigate(['/dashboard']);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  });
};
