<main
  class="container-fluid bg-secondary bg-center d-flex align-items-center justify-content-center"
  [ngStyle]="{ 'height.px': outerHeight }"
>
  <div
    class="col-12 col-md-7 col-lg-4 col-xl-3 bg-white-semi-transparent rounded-3"
  >
    <router-outlet></router-outlet>
  </div>
</main>
