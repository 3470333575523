import { Component } from '@angular/core';
import { SlideMenuService } from '../../services/slide-menu.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrl: './header-dashboard.component.scss',
})
export class HeaderDashboardComponent {
  constructor(
    public authService: AuthService,
    public slideMenuService: SlideMenuService
  ) {}

  signOut() {
    this.authService.signOut();
  }
}
