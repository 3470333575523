import { Component } from '@angular/core';
import { SlideMenuService } from './services/slide-menu.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'cleversec-contador-dashboard';

  constructor(
    public loaderService: LoaderService,
    public slideMenuService: SlideMenuService
  ) {}
}
